export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "Bamboo Fishing Rod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bamboo Fishing Rod"])}
      },
      "zh": {
        "Bamboo Fishing Rod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹子鱼竿"])},
        "Wooden Fishing Rod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木制鱼竿"])},
        "Fiberglass Fishing Rod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玻璃钢鱼竿"])},
        "Carbon Fiber Fishing Rod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["碳纤维鱼竿"])},
        "Silver Fishing Rod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白银鱼竿"])},
        "Gold Fishing Rod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黄金鱼竿"])},
        "Platinum Fishing Rod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铂金鱼竿"])},
        "Plastic Fish Trap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["塑料渔笼"])},
        "Bamboo Fish Trap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["竹子渔笼"])},
        "Nylon Fish Trap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尼龙渔笼"])},
        "Wire Fish Trap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钢丝渔笼"])},
        "Golden Fish Trap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黄金渔笼"])},
        "Platinum Fish Trap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铂金渔笼"])},
        "Wooden Treasure Chest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木制宝箱"])},
        "Silver Treasure Chest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白银宝箱"])},
        "Golden Treasure Chest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黄金宝箱"])},
        "Catmint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["猫薄荷"])},
        "Watermelon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西瓜"])},
        "Wheat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小麦"])},
        "Red Mushroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红蘑菇"])},
        "Leek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韭葱"])},
        "Dandelion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒲公英"])},
        "Shrimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虾"])},
        "Crab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["螃蟹"])},
        "Blowfish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["河豚"])},
        "Giant Salamander": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["娃娃鱼"])},
        "Northern Pike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白斑狗鱼"])},
        "Crocodile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鳄鱼"])},
        "White Fish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白条鱼"])},
        "Opsariichthys Bidens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马口鱼"])},
        "Tilapia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["罗非鱼"])},
        "Grass Carp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草鱼"])},
        "Bighead Carp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["胖头鱼"])},
        "Perch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鲈鱼"])},
        "Snakehead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑鱼"])},
        "Crucian Carp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鲫鱼"])},
        "Clam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蚌"])},
        "Eel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黄鳝"])},
        "Snail Carp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["青鱼"])},
        "Catfish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鲶鱼"])},
        "Hairtail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["带鱼"])},
        "Sea Urchins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海胆"])},
        "Octopus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["八爪鱼"])},
        "Shark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大鲨鱼"])},
        "Pacific Saury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秋刀鱼"])},
        "Sea Turtle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海龟"])},
        "Sardine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙丁鱼"])},
        "Oyster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牡蛎"])},
        "Pomfret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鲳鱼"])},
        "Grouper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["石斑鱼"])},
        "Seahorse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海马"])},
        "Sea Cucumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海参"])},
        "Unagi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鳗鱼"])},
        "Flatfish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比目鱼"])},
        "Salmon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三文鱼"])},
        "Kinmedai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金目鲷"])}
      }
    }
  })
}
